import React from "react"
import { Link } from "gatsby"
import { isDesktop } from 'react-device-detect';

import { useMagnetic } from "./hooks/useMagnetic"

export const IndexLink = (props) => {
    const {x, y, textX, textY, mouseEnter, mouseMove, mouseLeave} = useMagnetic()

    return (
			<Link 
				to={`/works/${props.id}`}
				className="sec__worksInnerBlockArcIn magnetic"
				onMouseEnter={mouseEnter}
				onMouseMove={mouseMove}
				onMouseLeave={mouseLeave}
			>
				<div className="sec__worksInnerBlockArcInFig">
					<figure
						style={
							isDesktop ?
							{transform: `translate(${x}px, ${y}px)`}
							:
							null
						}
					>
						<img src={props.url} alt={props.title} />
					</figure>
				</div>
				<div className="sec__worksInnerBlockArcInTxt">
					<h2>{props.title}</h2>
					<span className="year">{props.launch.replace(/\.[0-9][0-9]/, "")}</span>
				</div>
				{isDesktop &&
					<span 
						className="cmn__mouse"
						style={{transform: `translate(${textX}px, ${textY}px)`}}
					>
						<span>
							<span>View</span>
						</span>
					</span>
				}
			</Link>
    )
}