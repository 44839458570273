import React from "react";
import { graphql } from "gatsby";
import { Controller, Scene } from 'react-scrollmagic';
import { isMobile } from 'react-device-detect';

import Seo from "../components/seo"
import { Layout } from "../components/layout"
import { MagLinks } from "../components/MagLinks"
import { IndexLink } from "../components/IndexLink"

const IndexPage = ({data}) => {
  return (
  <Layout>
    <Seo title="" />
      <div className="mv">
        <Controller>
          <Scene 
            triggerHook="onLeave"
            triggerElement=".mv"
            duration={500}
          >
            {(progress) => (
              <div id="ttl" className="mv__inner">
                <h1 style={{opacity: `${1 - progress}`}}>
                  <span
                    className="ttlSpan"
                    style={{transform: `translateX(${-10 * progress}px)`}}
                  >
                    <span 
                      className="ttlSpan__inner"
                    >
                      Be interesting
                    </span>
                  </span>
                  <span
                    className="ttlSpan"
                    style={{transform: `translateX(${10 * progress}px)`}}
                  >
                    <span 
                      className="ttlSpan__inner"
                    >
                      and set a new
                    </span>
                  </span>
                  <span 
                    className="ttlSpan"
                    style={{transform: `translateX(${-10 * progress}px)`}}
                  >
                    <span 
                      className="ttlSpan__inner"
                    >
                      precedent{!isMobile && <span className="ttlSpan__innerMin">面白さで、<br className="br__sp"/>新しい前例をつくる</span>}
                    </span>
                  </span>
                  <span 
                    className="ttlSpan"
                    style={{transform: `translateX(${10 * progress}px)`}}
                  >
                    <span className="ttlSpan__inner">
                      <span className="ttlSpan__innerMin">面白さで、新しい前例をつくる</span>
                    </span>
                  </span>
                </h1>
              </div>
            )}
          </Scene>
          <Scene 
            classToggle={[".mv__under", "enter"]} 
            triggerHook="onEnter"
            triggerElement=".enterContents"
          >
            <div className="mv__under">
              <div className={`mv__underLeft`}>
                We are a creative digital studio
              </div>
              <div className={`mv__underCenter`}>
                Based in Yamagata, Japan
              </div>
              <div className={`mv__underRight`} />
            </div>
          </Scene>
        </Controller>
      </div>
      <section className="sec__works enterContents">
        <div className="sec__worksInner">
          <div className="sec__worksInnerBlock">
            {data.allMicrocmsWorks.edges.map(({node}) => (
              <article key={node.worksId} className="sec__worksInnerBlockArc">
                <IndexLink 
                  id={node.worksId}
                  url={node.eyecatch.url}
                  title={node.title}
                  launch={node.launch}
                />
              </article>
            ))}
          </div>
          <MagLinks path="/works/">
            <div>
              <p>View all works</p>
              <span>{data.allMicrocmsWorks.totalCount}</span>
            </div>
          </MagLinks>
        </div>
      </section>
  </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    allMicrocmsWorks(limit: 5, sort: {fields: publishedAt, order: DESC}) {
      totalCount
      edges {
        node {
          title
          launch
          worksId
          eyecatch {
            url
          }
        }
      }
    }
  }
`